import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";
// import { urlFor, client } from "../../client";

const About = () => {
  const [abouts, setAbouts] = useState([]);

  // useEffect(() => {
  //   const query = '*[_type == "abouts"]';

  //   client.fetch(query).then((data) => {
  //     setAbouts(data);
  //   });
  // }, []);

  return (
    <>
      <h2 className="head-text">
        I Know that <span>Good Design</span> <br />
        means <span>Good Business</span>
      </h2>

      <div className="app__profiles">
        {/* {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} loading="lazy" alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
        ))} */}
        👋 Hi, I’m Selahadin Jemal. A Software Engineer. I Specialize in
        Full-Stack Web development. I Mainly use React in the frontend and I use
        popular UILibraries like Material UI and CSS Libraries like Tailwind.
        And for the backend I use Laravel, Spring-boot, Dot net, and Node with
        the usual DBMSs and their ORMs. I call my self a "realistic
        perfectionist" which by my definitions is, a person who worries / nerds
        about the minute details of a task to a reasonable degree. well a little
        above that.. As a software engineer, I like to challenge my self and
        wear different hats. I'm interested in all aspect of a software journey
        i.e. programming, testing, ci/cd, servers etc..
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
