import React from 'react';
import { BsTwitter, BsInstagram, BsLinkedin, BsGithub} from 'react-icons/bs';
// import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
    <a href="https://www.linkedin.com/in/selahadin-jemal-a9b178155/"><BsLinkedin /></a>
    </div>
    <div>
      <a href="https://github.com/selahjs"><BsGithub /></a>
    </div>
    <div>
    <a href="https://twitter.com/JemalSelahadin"><BsTwitter /></a>
    </div>
    <div>
    <a href="https://www.instagram.com/salah.jemal/"><BsInstagram /></a>
    </div>
    
  </div>
);

export default SocialMedia;
