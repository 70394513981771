export const data = {
    work: [
        {
            id: 1,
            imgUrl: '/assets/Selahadin-Jemal-Afrozeal-smart-engineering-plc-portfolio.webp',
            name: '',
            projectLink: 'https://smartengineeringplc.com',
            codeLink: '',
            title: 'Landing Website',
            description: 'A Construction Engineering Company',
            tags: ['Web App', 'WordPress', 'All']
          },
        {
            id:2,
            imgUrl: '/assets/Selahadin-Jemal-Afrozeal-afendi-business-plc-portfolio.webp',
            name: '',
            projectLink: 'https://afendisfam.com/',
            codeLink: '',
            title: 'Business Website',
            description: 'Business Group',
            tags: ['Web App', 'WordPress', 'All']
          },
        {
            id: 3,
            imgUrl: '/assets/Selahadin-Jemal-Afrozeal-bab-al-noor-portfolio.webp',
            name: '',
            projectLink: 'https://babalnoor.org/',
            codeLink: '',
            title: 'Landing Website',
            description: 'A Cahrity Organization',
            tags: ['Web App', 'WordPress', 'All']
          },
        {
            id:4,
            imgUrl: '/assets/Selahadin-Jemal-Afrozeal-wabe-waabee-org-portfolio.webp',
            name: '',
            projectLink: 'https://waabee.org/',
            codeLink: '',
            title: 'NGO Website',
            description: 'A self-help charity organization',
            tags: ['Web App', 'WordPress', 'All']
          },
        {
            id:5,
            imgUrl: '/assets/Selahadin-Jemal-Afrozeal-telegram-react-portfolio.webp',
            name: '',
            projectLink: 'https://selahjs.github.io/telgram-react/',
            codeLink: 'https://github.com/selahjs/telgram-react',
            title: 'Telegram Clone',
            description: 'Telegram Client Web App built with React',
            tags: ['React JS', 'Personal', 'Web App', 'Mobile App', 'All']
          },
        {
            id:6,
            imgUrl: '/assets/Selahadin-Jemal-Afrozeal-van-life-portfolio.webp',
            name: '',
            projectLink: 'https://vanlife101.netlify.app/',
            codeLink: 'https://github.com/selahjs/van-life',
            title: 'Van Renting Website',
            description: 'An ongoing project',
            tags: ['React JS', 'Personal', 'All']
          },
        {
            id:7,
            imgUrl: '/assets/Selahadin-Jemal-Afrozeal-markup-note-taking-app-portfolio.webp',
            name: '',
            projectLink: 'https://selahjs.github.io/react-markup-note-app/',
            codeLink: 'https://github.com/selahjs/react-markup-note-app',
            title: 'Markup-Coverter',
            description: 'A react app that converts markup/markdown text to normal text',
            tags: ['React JS', 'Personal', 'All']
          },
    ],
    skills: [
        {
            bgColor: "#edf2f8",
            name: "React",
            icon: "/assets/react.png",
        },
        {
            bgColor: "#edf2f8",
            name: "Redux",
            icon: "/assets/redux.png",
        },
        {
            bgColor: "#edf2f8",
            name: "Typescript",
            icon: "/assets/typescript.png",
        },
        {
            bgColor: "#edf2f8",
            name: "Material UI",
            icon: "/assets/material-ui-selahadin-jemal-logo.png",
        },
        {
            bgColor: "#edf2f8",
            name: "Laravel",
            icon: "/assets/laravel-experience-selahadin-jemal-logo.png",
        },
        {
            bgColor: "#edf2f8",
            name: "MySql",
            icon: "/assets/selahadin-jemal-mysql.png",
        },
        {
            bgColor: "#edf2f8",
            name: "Git",
            icon: "/assets/git.png",
        },
        {
            bgColor: "#edf2f8",
            name: "CI/CD",
            icon: "/assets/selahadin-jemalpng-transparent-continuous-integration-devops-continuous-delivery-software-testing-ci-cd-le-bio-au-risque-de-se-perdre-text-logo-agile-software-development.png",
        },
        {
            bgColor: "#edf2f8",
            name: "Node",
            icon: "/assets/node.png",
        },
        {
            bgColor: "#edf2f8",
            name: "Tailwind CSS",
            icon: "/assets/tailwind-css-selahadin-jemal-logo.png",
        },
    ],
    
// {/* skill: name, bgColor, icon,  */}
        // {/* experience: year, works[{name, company, desc}],  */}
    experience: [
        {
            year: '2023 - Current',
            works: [
                {
                    name: 'Software Engineer',
                    company: 'John Snow Inc,',
                    desc: 'I currenlty work as a full-stack engineer',
                }
            ]
        },
        {
            year: '2020 - Current',
            works: [
                {
                    name: 'Freelance Software Developer',
                    company: 'Freelance,',
                    desc: 'I design & develop websites and system for companies',
                }
            ]
        },
        {
            year: '2022 - 2023',
            works: [
                {
                    name: 'Fullstack Developer',
                    company: 'Afrozeal IT Consultncy',
                    desc: 'Mainly Develop projects using mainly React as a frontend and DotNet as a backend',
                }
            ]
        },
        {
            year: '2021 - 2022',
            works: [
                {
                    name: 'Frontend Developer',
                    company: 'Smart Engineering PLC',
                    desc: 'Mainly Develop UI Components using CSS Libraries like Tailwind and MUI',
                }
            ]
        },
        {
            year: '2019 - 2020',
            works: [
                {
                    name: 'Software Engineering Intern',
                    company: 'Blue Moon Ethiopia',
                    desc: 'Mainly Woked on Displaying Live Sensory information from a python servor to an android app',
                }
            ]
        },
    ],
}